<template>
  <v-container>
    <perseu-card title="Pontos">
      <v-btn color="secondary" slot="title-right" @click="create">
        Novo Ponto
      </v-btn>
      <v-flex slot="content">
        <filters-panel>
          <v-form @submit.prevent="list">
            <v-row justify="center">
              <v-col md="2" sm="4" cols="12">
                <v-switch v-model="filter.active" label="Ativos"></v-switch>
              </v-col>
              <v-col md="2" sm="4" cols="12">
                <v-checkbox
                  v-model="filter.type"
                  value="1"
                  class="mx-2"
                  label="Impactação"
                />
              </v-col>
              <v-col md="2" sm="4" cols="12">
                <v-checkbox
                  v-model="filter.type"
                  value="0"
                  class="mx-2"
                  label="Rastreio"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field filled v-model.trim="filter.name" label="Nome" />
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  @click="clearQuery"
                  outlined
                  color="secondary"
                  class="mr-3"
                >
                  Limpar
                </v-btn>
                <v-btn @click="list" color="secondary"> Buscar </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </filters-panel>
        <v-data-table
          :items="points"
          :headers="headers"
          class="elevation-1"
          :options.sync="options"
          :server-items-length="total"
        >
          <template v-slot:item.image="{ item }">
            <v-avatar size="70" class="ma-3">
              <v-img :src="item.image" />
            </v-avatar>
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.type | transformPointType }}
          </template>
          <template v-slot:item.actions="props">
            <v-btn icon @click="edit(props.item)" color="secondary">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon>check</v-icon>
            </v-btn>
            <v-btn :disabled="props.item.image.startsWith('https://')" icon @click="updateImage(props.item)" color="secondary">
              <v-icon>refresh</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <point-form ref="form" @create="addToList" @update="updateItem" />
  </v-container>
</template>

<script>
import { find, save, findOne } from "@/services/points-service";
import { getBodyPointPreSignedUrl,uploadToS3 } from "@/services/aws-service";
export default {
  components: {
    "point-form": () => import("@/components/Points/Form"),
  },
  data: () => ({
    filter: {
      active: true,
    },
    point: {},
    points: [],
    headers: [
      { text: "ID", value: "id", sortable: false, width: "10%" },
      { text: "Imagem", value: "image", sortable: false, width: "10%" },
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type" },
      { text: "Ações", value: "actions", width: "20%", sortable: false },
    ],
    dialog: false,
    options: {
    
    },
    total: 0,
  }),
  watch: {
    options: {
      handler() {
        this.list();
      },
      deep: true,
    },
  },
  methods: {
    create() {
      this.$refs.form.create();
      this.dialog = true;
    },
    async edit(item) {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOne(item.id);
        this.$refs.form.edit(data);
        this.dialog = true;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    async activeOrDeactivate(item, target) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = target;
        await save(item);
        this.removeFromList(item);
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
      
        let { data } = await find({ ...this.options, filter: this.filter });
        this.points = data.items;
        this.total = data.total;
       
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },

    async updateImage(point){
      const base64 = point.image;
        const file = await this.dataURLtoFile(base64, 'image.png');
        const fileType = file.type.split('/')[1];
        const resourceUrl = await this.upload(file, fileType);
        point.image = resourceUrl;
       if(resourceUrl) await save(point);
       alert(point.image);
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  },

    async generateUrl(fileType) {
      const {
        data: { resourceUrl, key, contentType, url },
      } = await getBodyPointPreSignedUrl(fileType);
      return {
        resourceUrl,
        key,
        contentType,
        url,
      };
    },
    async upload(file, fileType) {
      try {
        const { resourceUrl, key, contentType, url } = await this.generateUrl(fileType);
        await uploadToS3({ image: file, key, url, contentType });
       
        return resourceUrl;
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    removeFromList(point) {
      let index = this.points.indexOf(point);
      this.points.splice(index, 1);
    },
    addToList(point) {
      this.points.push(point);
      this.dialog = false;
      this.point = {};
    },
    clearQuery() {
      this.filter = {
        active: true,
      };
    },
    updateItem(newItem) {
      const index = this.points.findIndex((point) => point.id === newItem.id);
      this.points.splice(index, 1, newItem);
    },
  },
};
</script>

<style></style>
